import '@babel/polyfill';
import {
  login,
  logout,
  addLocation,
  getLocation,
  updateLocation,
  deleteLocation,
  updateResult,
  updateChartSettings,
  updatePersonalChartSettings,
  getYearlyChart,
} from './apis';
import { showAlert } from './alerts';

// DOM ELEMENTS
const loginForm = document.querySelector('.form');
const logoutBtn = document.querySelector('.logout-btn');
const addLocationForm = document.querySelector('#addLocationForm');
const updateLocationFormLocationSelectEl = document.querySelector(
  '#updateLocationForm-selectLocation'
);
const updateLocationForm = document.querySelector('#updateLocationForm');
const deleteLocationForm = document.querySelector('#deleteLocationForm');
const updateResultForm = document.querySelector('#updateResultForm');
const updateChartSettingsForm = document.querySelector(
  '#updateChartSettingsForm'
);
const updatePersonalChartSettingsForm = document.querySelector(
  '#updatePersonalChartSettingsForm'
);
const yearlyChartCheckButton = document.querySelector(
  '#yearlyChartCheckButton'
);

// Authentication
if (loginForm) {
  loginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.querySelector('#email').value;
    const password = document.querySelector('#password').value;
    login(email, password);
  });
}

if (logoutBtn) logoutBtn.addEventListener('click', logout);

// Location
if (addLocationForm) {
  addLocationForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const locationName = document.querySelector(
      '#addLocationForm-locationName'
    ).value;
    const timeLabel = document.querySelector(
      '#addLocationForm-timeLabel'
    ).value;
    await addLocation(locationName, timeLabel);
  });
}

if (updateLocationFormLocationSelectEl) {
  updateLocationFormLocationSelectEl.addEventListener('change', async (e) => {
    const locationId = updateLocationFormLocationSelectEl.value;

    const location = await getLocation(locationId);

    const locationNameEl = document.querySelector(
      '#updateLocationForm-locationName'
    );
    const timeLabelEl = document.querySelector('#updateLocationForm-timeLabel');
    if (location) {
      locationNameEl.value = location?.name;
      timeLabelEl.value = location?.timeLabel;
    }
  });
}

if (updateLocationForm) {
  updateLocationForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const locationId = updateLocationFormLocationSelectEl.value;
    const locationName = document.querySelector(
      '#updateLocationForm-locationName'
    ).value;
    const timeLabel = document.querySelector(
      '#updateLocationForm-timeLabel'
    ).value;

    const dataToUpdate = {
      name: locationName,
      timeLabel,
    };

    await updateLocation(locationId, dataToUpdate);
  });
}

if (deleteLocationForm) {
  deleteLocationForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const locationId = document.querySelector(
      '#deleteLocationForm-selectLocation'
    ).value;
    await deleteLocation(locationId);
  });
}

if (updateResultForm) {
  updateResultForm.addEventListener('submit', async function (e) {
    e.preventDefault();

    const selectLocationEl = document.querySelector(
      '#updateResultForm-selectLocation'
    );
    const locationId = selectLocationEl?.value;

    const resultDateEl = document.querySelector('#updateResultForm-resultDate');
    const resultDate = resultDateEl?.value;

    const resultEl = document.querySelector('#updateResultForm-result');
    const result = resultEl?.value;

    await updateResult(locationId, resultDate, result);
  });
}

if (updateChartSettingsForm) {
  updateChartSettingsForm.addEventListener('submit', async function (e) {
    e.preventDefault();

    const isChartEnabledEl = document.querySelector(
      '#updateChartSettingsForm-isChartEnabled'
    );
    const isChartEnabled = isChartEnabledEl?.value;

    const headerTextEl = document.querySelector(
      '#updateChartSettingsForm-header'
    );
    const headerText = headerTextEl?.value;

    const footerTextEl = document.querySelector(
      '#updateChartSettingsForm-footer'
    );
    const footerText = footerTextEl?.value;

    const whatsappNoTextEl = document.querySelector(
      '#updateChartSettingsForm-whatsappNo'
    );
    const whatsappNoText = whatsappNoTextEl?.value;

    await updateChartSettings(
      isChartEnabled,
      headerText,
      footerText,
      whatsappNoText
    );
  });
}

if (updatePersonalChartSettingsForm) {
  updatePersonalChartSettingsForm.addEventListener(
    'submit',
    async function (e) {
      e.preventDefault();

      const isPersonalChartEnabledEl = document.querySelector(
        '#updatePersonalChartSettingsForm-isPersonalChartEnabled'
      );
      const isPersonalChartEnabled = isPersonalChartEnabledEl?.value;

      const nameEl = document.querySelector(
        '#updatePersonalChartSettingsForm-name'
      );
      const name = nameEl?.value;

      const whatsappNumberEl = document.querySelector(
        '#updatePersonalChartSettingsForm-whatsappNumber'
      );
      const whatsappNumber = whatsappNumberEl?.value;

      const telegramNumberEl = document.querySelector(
        '#updatePersonalChartSettingsForm-telegramNumber'
      );
      const telegramNumber = telegramNumberEl?.value;

      const topTextEl = document.querySelector(
        '#updatePersonalChartSettingsForm-topText'
      );
      const topText = topTextEl?.value;

      await updatePersonalChartSettings(
        isPersonalChartEnabled,
        name,
        whatsappNumber,
        telegramNumber,
        topText
      );
    }
  );
}

if (yearlyChartCheckButton) {
  yearlyChartCheckButton.addEventListener('click', async () => {
    const selectedLocationId = document.querySelector('#selected-location').value;
    const selectedYear = document.querySelector('#selected-year').value;

    if (!selectedLocationId && !selectedYear) {
      showAlert('error', 'Please select name and year!');
      return;
    }
    const res = await getYearlyChart(selectedLocationId, selectedYear);
    if(!res) return;

    
    const yearlyChartNameContainer = document.querySelector("#yearlyChartNameContainer");
    yearlyChartNameContainer.classList.remove("d-none");
    yearlyChartNameContainer.innerHTML = res.chartTitle;
    
    const yearlyChartContainer = document.querySelector("#yearlyChartContainer");
    const tableHTML = generateTableHtml(res.data, selectedYear);
    yearlyChartContainer.innerHTML = tableHTML;
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const renderTimeEl = document.getElementById('render-time');

  if (renderTimeEl) {
    function formatTime(date) {
      const dateString = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      const timeString = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      });
      return `${dateString} ${timeString}`;
    }

    function updateTime() {
      const now = new Date();
      renderTimeEl.textContent = formatTime(now);
    }

    setInterval(updateTime, 1000);
    updateTime(); // Initial call to display time immediately on page load
  }
});


// Function to generate table HTML
function generateTableHtml(yearlyData, selectedYear) {
  let html = `
    <table class="table table-bordered mb-0">
      <thead>
        <tr>
          <th class="table-active" scope="col">${selectedYear}</th>`;

  // Generate table headers for months
  yearlyData.months.forEach(month => {
    html += `<th class="table-active" scope="col">${month}</th>`;
  });

  html += `</tr></thead><tbody>`;

  // Generate table rows for dates
  yearlyData.dates.forEach(date => {
    html += `<tr><th class="table-active" scope="row">${date}</th>`;

    yearlyData.months.forEach(month => {
      html += `<td class="align-middle">`;
      if (yearlyData?.table?.[date] && yearlyData?.table?.[date]?.[month] && yearlyData.table[date][month]?.toLowerCase() !== "wait") {
        html += `<span class="result-text">${yearlyData.table[date][month]}</span>`;
      } else {
        html += `<span class="result-text">-</span>`;
      }
      html += `</td>`;
    });

    html += `</tr>`;
  });

  html += `</tbody></table>`;
  return html;
}